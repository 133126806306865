@import (reference) '~@zola/zola-ui/src/styles/common/_colorsBaby.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.errorContent {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding: 40px;

  .not-desktop-v2({
    padding: 24px;
  });
}
